import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth-service.service'
import { environment } from 'src/environments/environment';
import BaseLinksJson from 'src/assets/base-assets/base-links.json';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertService } from './alert.service';
declare var require: any;
var Excel = require('exceljs');
import * as fs from 'file-saver';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class HttpUtilityService {
  //currentEnvironment
  private currentEnvironment;
  //httpRequestsBuffer
  private _httpRequestsBuffer: Array<HttpRequestBufferItem> = [];
  public httpRequestsBufferObservable = new BehaviorSubject<Array<HttpRequestBufferItem>>([]);
  private _httpRequestsBufferLog: Array<HttpRequestBufferItemLog> = [];
  private _logRequests: boolean = false;
  public links = {
    client: "",
    server: "",
    chathub: "",
    apigateway: "",
    appUID: "",
    privacyPolicy: "",
    marketing: ""
  };

  constructor(private router: Router, public autSrv: AuthService, private translate: TranslateService, private httpClient: HttpClient, private _alertSrv: AlertService,
    private _platform: Platform) {
    //services
    if (environment["production"]) {
      this.links.server = BaseLinksJson.prod.server;
      this.links.chathub = BaseLinksJson.prod.chathub;
      this.links.apigateway = BaseLinksJson.prod.apigateway;
      this.currentEnvironment = "production";
    }
    else if (environment["preProduction"]) {
      this.links.server = BaseLinksJson.preProd.server;
      this.links.chathub = BaseLinksJson.preProd.chathub;
      this.links.apigateway = BaseLinksJson.preProd.apigateway;
      this.currentEnvironment = "preProduction";
    }
    else if (environment["test"]) {
      this.links.server = BaseLinksJson.test.server;
      this.links.chathub = BaseLinksJson.test.chathub;
      this.links.apigateway = BaseLinksJson.test.apigateway;
      this.currentEnvironment = "test";
    }
    else if (environment["stage"]) {
      this.links.server = BaseLinksJson.stage.server;
      this.links.chathub = BaseLinksJson.stage.chathub;
      this.links.apigateway = BaseLinksJson.stage.apigateway;
      this.currentEnvironment = "stage";
    }
    else if (environment["local"]) {
      this.links.server = BaseLinksJson.local.server;
      this.links.chathub = BaseLinksJson.local.chathub;
      this.links.apigateway = BaseLinksJson.local.apigateway;
      this.currentEnvironment = "local";
    }
    //imma-ext
    else if (environment["productionImmaExt"]) {
      this.links.server = BaseLinksJson.prodImmaExt.server;
      this.links.chathub = BaseLinksJson.prodImmaExt.chathub;
      this.currentEnvironment = "productionImmaExt";
    }
    else if (environment["preProductionImmaExt"]) {
      this.links.server = BaseLinksJson.preProdImmaExt.server;
      this.links.chathub = BaseLinksJson.preProdImmaExt.chathub;
      this.currentEnvironment = "preProductionImmaExt";
    }
    else if (environment["testImmaExt"]) {
      this.links.server = BaseLinksJson.testImmaExt.server;
      this.links.chathub = BaseLinksJson.testImmaExt.chathub;
      this.currentEnvironment = "testImmaExt";
    }
    else if (environment["localImmaExt"]) {
      this.links.server = BaseLinksJson.localImmaExt.server;
      this.links.chathub = BaseLinksJson.localImmaExt.chathub;
      this.currentEnvironment = "localImmaExt";
    }
    //appDriver
    else if (environment["appVodafoneTest"]) {
      this.links.server = BaseLinksJson.appVodafoneTest.server;
      this.links.apigateway = BaseLinksJson.appVodafoneTest.apigateway;
      this.links.appUID = this._platform.is('ios') ? BaseLinksJson.appVodafoneTest.appUID.ios : this._platform.is('android') ? BaseLinksJson.appVodafoneTest.appUID.android : '';
      this.currentEnvironment = "appVodafoneTest";
    }
    else if (environment["appVodafoneProduction"]) {
      this.links.server = BaseLinksJson.appVodafoneProd.server;
      this.links.apigateway = BaseLinksJson.appVodafoneProd.apigateway;
      this.links.appUID = this._platform.is('ios') ? BaseLinksJson.appVodafoneProd.appUID.ios : this._platform.is('android') ? BaseLinksJson.appVodafoneProd.appUID.android : '';
      this.currentEnvironment = "appVodafoneProduction";
    }
    else if (environment["appSafoTest"]) {
      this.links.server = BaseLinksJson.appSafoTest.server;
      this.links.apigateway = BaseLinksJson.appSafoTest.apigateway;
      this.links.appUID = this._platform.is('ios') ? BaseLinksJson.appSafoTest.appUID.ios : this._platform.is('android') ? BaseLinksJson.appSafoTest.appUID.android : '';
      this.links.privacyPolicy = BaseLinksJson.appSafoTest.privacyPolicy;
      this.links.marketing = BaseLinksJson.appSafoTest.marketing;
      this.currentEnvironment = "appSafoTest";
    }
    else if (environment["appSafoProd"]) {
      this.links.server = BaseLinksJson.appSafoProd.server;
      this.links.apigateway = BaseLinksJson.appSafoProd.apigateway;
      this.links.appUID = this._platform.is('ios') ? BaseLinksJson.appSafoProd.appUID.ios : this._platform.is('android') ? BaseLinksJson.appSafoProd.appUID.android : '';
      this.links.privacyPolicy = BaseLinksJson.appSafoProd.privacyPolicy;
      this.links.marketing = BaseLinksJson.appSafoProd.marketing;
      this.currentEnvironment = "appSafoProd";
    }
    //default (services local)
    else {
      this.links.server = BaseLinksJson.local.server;
      this.links.chathub = BaseLinksJson.local.chathub;
      this.currentEnvironment = "local";
    }
  }

  //get http header
  public getHttpHeader() {
    return new Promise((resolve, reject) => {
      this.autSrv.getAuthObject().then((res) => {
        resolve(res);
      }).catch(() => {
        reject("getHttpHeader error")
      })
    });
  }

  //get environment
  public getEnvironment() {
    return this.currentEnvironment;
  }

  //is environment services
  public isEnvironmentServices() {
    return ["local", "test", "preProduction", "production"].includes(this.currentEnvironment);
  }

  //is environment imma
  public isEnvironmentImmaExt() {
    return ["localImmaExt", "testImmaExt", "preProductionImmaExt", "productionImmaExt"].includes(this.currentEnvironment);
  }

  //is environment test
  public isEnvironmentTest() {
    return ["test"].includes(this.currentEnvironment);
  }

  //getBaseUri
  public getBaseUri() {
    return document.baseURI;
  }

  //getRequestError
  public getRequestError(requestResponse) {
    return new Promise((resolve) => {
      this.translate.get('errors').subscribe((errors: any) => {
        //errore tradotto da codice errore
        if (requestResponse && requestResponse.error && requestResponse.error.value && requestResponse.error.value.code)
          resolve(errors[requestResponse.error.value.code] ? errors[requestResponse.error.value.code] : errors["com-error"]);
        //requestResponse.error.errors
        else if (requestResponse && requestResponse.error && requestResponse.error.errors && requestResponse.error.errors.length > 0) {
          resolve(requestResponse.error.errors)
        }
        //error.Message
        else if (requestResponse && requestResponse.error && requestResponse.error.Message)
          resolve(requestResponse.error.Message)
        //error.errorDisplayMessage
        else if (requestResponse && requestResponse.error && requestResponse.error.errorDisplayMessage)
          resolve(requestResponse.error.errorDisplayMessage)
        //message
        else if (requestResponse && requestResponse.message)
          resolve(requestResponse.message)
        //tutto il resto
        else if (requestResponse && requestResponse.error)
          resolve(requestResponse.error)
        //stringa
        else if (typeof (requestResponse) == 'string')
          resolve(requestResponse)
        //nesun codice
        else
          resolve(errors["1"])
      });
    })
  };

  //generic get
  public genericGet(endpoint) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(endpoint).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      })
    });
  }

  //generic post
  public genericPost(endpoint, payload) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(endpoint, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      })
    });
  }

  //generic request
  public genericRequest(endpoint, method, payload, responseType?) {
    return new Promise((resolve, reject) => {
      if (method == "post") {
        this.httpClient.post(endpoint, payload, { responseType: responseType ? responseType : 'json' }).subscribe((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        })
      } else if (method == "get") {
        this.httpClient.get(endpoint, { responseType: responseType ? responseType : 'json' }).subscribe((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        })
      }
    });
  }

  //sendHttpRequest
  public sendHttpRequest(url: string, method: 'POST' | 'GET', payload: any, responseType: any, observeResponse: boolean, tags: Array<string>) {
    let options = { responseType: responseType ? responseType : "json", observe: "response" as const };
    let id = "httpRequest-" + url + "-" + new Date().getTime();
    if (method == "POST") {
      this._httpRequestsBuffer.push(new HttpRequestBufferItem(id, url, tags, this.httpClient.post(url, payload, options)))
    } else if (method == "GET") {
      this._httpRequestsBuffer.push(new HttpRequestBufferItem(id, url, tags, this.httpClient.get(url, options)))
    }
    this.httpRequestsBufferObservable.next(this._httpRequestsBuffer);
    return new Promise((resolve, reject) => {
      let request: HttpRequestBufferItem = this._httpRequestsBuffer.filter((item: HttpRequestBufferItem) => { return item.getRequestId() == id })[0];
      if (request) {
        if (this._logRequests) {
          this._httpRequestsBufferLog.push({ url: url, id: id, tags: tags, type: "SENT", currentBufferLength: this._httpRequestsBuffer.length, time: this.getTime(), error: null, contentLengh: null });
          this._httpRequestsBufferLog = this._httpRequestsBufferLog.slice(-200);
        }
        request.setHttpSubscription(request.getHttpObservable().subscribe((res) => {
          if (this._logRequests) {
            this._httpRequestsBufferLog.push({ url: url, id: id, tags: tags, type: "SUCCESS", currentBufferLength: this._httpRequestsBuffer.length, time: this.getTime(), error: null, contentLengh: res?.headers?.get('content-length') });
            this._httpRequestsBufferLog = this._httpRequestsBufferLog.slice(-200);
          }
          resolve(observeResponse ? res : res.body);
        }, (err) => {
          if (this._logRequests) {
            this._httpRequestsBufferLog.push({ url: url, id: id, tags: tags, type: "ERROR", currentBufferLength: this._httpRequestsBuffer.length, time: this.getTime(), error: err ? JSON.stringify(err) : null, contentLengh: err?.headers?.get('content-length') });
            this._httpRequestsBufferLog = this._httpRequestsBufferLog.slice(-200);
          }
          reject(err);
        }, () => {
          if (request.getHttpSubscription())
            request.getHttpSubscription().unsubscribe();
          this._httpRequestsBuffer = this._httpRequestsBuffer.filter((item: HttpRequestBufferItem) => { return item.getRequestId() != id });
          this.httpRequestsBufferObservable.next(this._httpRequestsBuffer);
          if (this._logRequests) {
            this._httpRequestsBufferLog.push({ url: url, id: id, tags: tags, type: "COMPLETE", currentBufferLength: this._httpRequestsBuffer.length, time: this.getTime(), error: null, contentLengh: null });
            this._httpRequestsBufferLog = this._httpRequestsBufferLog.slice(-200);
          }
        })
        )
      }
    });
  }
  //abortHttpRequestsByTag
  public abortHttpRequestsByTag(tag: string) {
    this._httpRequestsBuffer.forEach((item: HttpRequestBufferItem) => {
      if (item.getRequestTags().includes(tag))
        item.getHttpSubscription().unsubscribe();
    })
    this._httpRequestsBuffer = this._httpRequestsBuffer.filter((item: HttpRequestBufferItem) => { return !item.getRequestTags().includes(tag) });
    this.httpRequestsBufferObservable.next(this._httpRequestsBuffer);
    if (this._logRequests)
      this._httpRequestsBufferLog.push({ url: null, id: null, tags: [tag], type: "ABORT BY TAG", currentBufferLength: this._httpRequestsBuffer.length, time: this.getTime(), error: null, contentLengh: null });
  }
  //startLogRequests
  public startLogRequests() {
    this._logRequests = true;
  }
  //endLogRequests
  public endLogRequests() {
    this._logRequests = false;
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet("workbook");
    worksheet.addRow(["Type", "Url", "Time", "Buffer Length", "Tags", "Content Length (B)", "Id", "Error"]);
    for (let item of this._httpRequestsBufferLog) {
      worksheet.addRow([item.type ? item.type : '-', item.url ? item.url : '-', item.time ? item.time : '-', item.currentBufferLength != null ? item.currentBufferLength : '-', item.tags ? item.tags.join(",") : '-', item.contentLengh != null ? item.contentLengh : '-', item.id ? item.id : '-', item.error ? item.error : '-']);
    }
    let dt = new Date();
    let fname = "HttpRequestsLog_" + (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()) + "-" + ((dt.getMonth() + 1) < 10 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + dt.getFullYear() + "_" + dt.getHours() + "-" + dt.getMinutes();
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
      this._httpRequestsBufferLog = [];
    });

  }
  //getTime
  private getTime() {
    let dt = new Date();
    return ("0" + dt.getHours()).slice(-2) + ":" + ("0" + dt.getMinutes()).slice(-2) + ":" + ("0" + dt.getSeconds()).slice(-2);
  }
}



export class HttpRequestBufferItem {
  private _requestId: string;
  private _requestUrl: string;
  private _requestTags: Array<string>;
  private _httpObservable: Observable<any>;
  private _httpSubscription: BehaviorSubject<any>;
  constructor(requestIdParam: string, requestUrlParam: string, requestTagsParam: Array<string>, httpObservableParam: Observable<any>) {
    this._requestId = requestIdParam;
    this._requestUrl = requestUrlParam;
    this._requestTags = requestTagsParam;
    this._httpObservable = httpObservableParam;
  }
  //getRequestId
  public getRequestId() {
    return this._requestId;
  }
  //getRequestTag
  public getRequestTags() {
    return this._requestTags;
  }
  //getHttpObservable
  public getHttpObservable() {
    return this._httpObservable;
  }
  //setHttpSubscription
  public setHttpSubscription(httpSubscription: any) {
    this._httpSubscription = httpSubscription;
  }
  //getHttpSubscription
  public getHttpSubscription() {
    return this._httpSubscription;
  }
}
export enum HttpRequestBufferItemTags {
  ODEVA_DETTAGLIO = "ODEVA_DETTAGLIO",
  ALD_POLLING = "ALD_POLLING"
}

interface HttpRequestBufferItemLog {
  url: string,
  id: string,
  type: "SENT" | "SUCCESS" | "ERROR" | "ABORT BY TAG" | "COMPLETE",
  tags: Array<string>,
  currentBufferLength: number,
  time: string,
  error: string,
  contentLengh: string
}