import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { AlertController } from '@ionic/angular';
import { LoaderService } from '../../services/loader.service'
import { AdminUserDetailService } from '../../services/admin-user-detail.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { HttpClient, HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { HttpUtilityService } from 'src/app/services/http-utility.service';
import { AppService } from 'src/app/services/app.service';
import { TablesPromptComponent } from './tables-prompt/tables-prompt.component';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
})
export class ChatBotComponent implements OnInit {
  public chatBot: ChatBotConfig = new ChatBotConfig();
  public text: string;

  constructor(
    public translate: TranslateService,
    private alertSrv: AlertService,
    public alertCtrl: AlertController,
    public loadingSrv: LoaderService,
    public adminUserDetailSrv: AdminUserDetailService,
    public storaveVariableSrv: StorageVariablesService,
    public router: Router,
    public httpUtilitySrv: HttpUtilityService,
    private _http: HttpClient,
    private _appSrv: AppService,
    public modalCtrl: ModalController) {

  }

  ngOnInit() {

  }

  //getListByEndpoint
  public getListByEndpoint(event, url, minLength?) {
    let autoSearchList = ["selectableTipoDocumento"];
    if ((autoSearchList.includes(event.component._element.nativeElement.id) || (event.text != null && event.text != undefined && event.text != "")) && (!minLength || event.text.length >= minLength) && !event.component.isSearching) {
      if (url.indexOf('#customerId#') != -1)
        url = url.replace('#customerId#', this.chatBot.customer?.value)
      url = url + "&value=" + event.text;
      event.component.startSearch();
      event.component.showLoading();
      this._getListByEndpoint(url).then((res: any) => {
        event.component.items = res.result;
        event.component.hideLoading();
        event.component.endSearch();
      }).catch((err) => {
        if (err && err.status && err.status != 401 && err.status != 403) {
          this.httpUtilitySrv.getRequestError(err).then((err: any) => {
            this.alertSrv.errorAlert(err);
            event.component.hideLoading();
            event.component.endSearch();
          })
        }
      })
    } else {
      event.component.items = null;
    }
  }
  //get list by endPoint
  private _getListByEndpoint(endPoint) {
    return new Promise((resolve, reject) => {
      this._http.get(endPoint).subscribe({
        next: (res) => { resolve(res) },
        error: (err) => { reject(err) }
      })
    });
  }

  //ask
  public ask() {
    this.chatBot.isLoading = true;
    if (this.chatBot.currentOption)
      this.chatBot.chat.push({ testo: JSON.parse(JSON.stringify(this.text)), idSessioneAI: this.chatBot.currentSessionId, type: 'human', opzioni: null, datasetJson: null })
    this._scrollToLastMessage();
    let newText: string = this.text != null && this.text != undefined && this.text != ''? JSON.parse(JSON.stringify(this.text)) : null;
    this.text = null;
    this._http.post('https://test.safogroup.eu/safoms-apigateway/Assistant/Domanda', {
      testo: newText,
      sessione: {
        idCliente: this.chatBot.customer?.value,
        idRiferimento: this.chatBot.fine?.multa?.idMultaIn,
        idSessioneAI: this.chatBot.currentSessionId ? this.chatBot.currentSessionId : null,
        codice: this.chatBot.currentOption ? this.chatBot.currentOption.codice : 'START',
      }
    })
      .subscribe({
        next: (res: any) => {
          this.chatBot.chat.push({
            testo: res.testo?.trim(),
            opzioni: res.opzioni,
            idSessioneAI: res.idSessioneAI,
            type: 'ai',
            datasetJson: this._extratJson(res.testo)
          })
          this.chatBot.currentSessionId = res.idSessioneAI ? res.idSessioneAI : this.chatBot.currentSessionId;
          this.chatBot.isLoading = false;
          if (!this.chatBot.datasetJson)
            this.chatBot.datasetJson = res.datasetJson;
          this._scrollToLastMessage();
        },
        error: (err) => {
          this.chatBot.isLoading = false;
          if (!err.status || (err && err.status && err.status != 401 && err.status != 403)) {
            this.httpUtilitySrv.getRequestError(err).then((err: any) => {
              this.chatBot.chat.push({ testo: err, idSessioneAI: null, opzioni: null, type: 'error', datasetJson: null })
              this._scrollToLastMessage();
            })
          }
        }
      })
  }

  //handleOption
  public handleOption(option: MessageOption) {
    this.chatBot.currentOption = option;
    this.chatBot.chat.shift();
  }

  //refreshChatBot
  public refreshChatBot() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('labels.resetChat') }, () => {
      this.chatBot = new ChatBotConfig(true);
    })
  }

  //scrollToLastMessage
  private _scrollToLastMessage() {
    setTimeout(() => {
      let messageDom = document.getElementById('message-' + (this.chatBot.chat.length - 1));
      if (messageDom) {
        messageDom.scrollIntoView({ behavior: 'smooth', block: "end" });
      }
    }, 200)
  }

  //searchKeyUp
  public searchKeyUp(event) {
    if (event.keyCode == 13 && this.text != '' && this.text && !this.chatBot.isLoading) {
      this.ask();
    }
  }

  //openTablesPrompt
  public async openTablesPrompt(datasetJson?: any) {
    let datasetJsonProps = datasetJson ? datasetJson : JSON.parse(this.chatBot.datasetJson);
    let modal = await this.modalCtrl.create({
      component: TablesPromptComponent,
      cssClass: 'tables-prompt-component',
      componentProps: { datasetJson: datasetJsonProps }
    })
    await modal.present();
  }

  //extractJson
  private _extratJson(text: string): object | Array<any> {
    if (!text || text == '')
      return null;
    const pattern = /```json([\s\S]*?)```/;
    const matches = text.match(pattern);
    if (matches) {
      try {
        let jsonObj = JSON.parse(matches[1].trim());
        if(!Array.isArray(jsonObj))
          jsonObj=[jsonObj];
        return jsonObj;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }
}
export class ChatBotConfig {
  public show: boolean;
  public bottom: number;
  public right: number;
  public chat: Array<ChatMessage>
  public customer: any;
  public fine: any;
  public currentSessionId: number;
  public currentOption: MessageOption;
  public isLoading: boolean;
  public isButtonPinned: boolean;
  public datasetJson: any;

  constructor(isReset?: boolean) {
    this.show = isReset ? true : false;
    this.bottom = 10;
    this.right = 10;
    this.chat = [];
    this.fine = null;
    this.customer = null;
    this.currentOption = null;
    this.currentSessionId = null;
    this.isLoading = false;
    this.isButtonPinned = true;
    this.datasetJson = null;
  }
}
export interface ChatMessage {
  idSessioneAI: number,
  opzioni: Array<MessageOption>
  testo: string,
  type: 'human' | 'ai' | 'error',
  datasetJson: any
}
export interface MessageOption {
  codice: string
  descrizione: string
  nome: string
}
