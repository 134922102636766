import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpUtilityService } from './http-utility.service';
import { AuthService } from './auth-service.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LegacyFrontendContainerService {
  //current legacy frontend
  public currentLegacyFrontendChangeByMenu = new BehaviorSubject<LegacyMenuItem>(null);
  //current legacyFrontend
  private _currentLegacyMenuItem: LegacyMenuItem;

  constructor(public http: HttpClient, private _httpUtilitySrv: HttpUtilityService, private _authSrv: AuthService, public location: Location) { }
  //se container in corso aggiornare src al click menu
  //se container cambia rotta internamente aggiornare currentLegacyFrontend

  //setCurrentLegacyFrontend
  public setCurrentLegacyFrontendByMenu(menuItem: LegacyMenuItem) {
    this._currentLegacyMenuItem = menuItem;
    this.currentLegacyFrontendChangeByMenu.next(menuItem);
  }
  public async setCurrentLegacyFrontendByIframe(iframeUrl: string) {
    let authObj: any = await this._authSrv.getAuthObject().catch(() => { });
    if (authObj?.currentMenu?.figli) {
      let menuItem: LegacyMenuItem = this._getLegacyMenuItemByUrl(iframeUrl, authObj?.currentMenu?.figli);
      if (menuItem) {
        this._currentLegacyMenuItem = menuItem;
        this.location.go(menuItem.url);
      }
    }
  }

  //menu
  public async checkForLegacyModules(items: Array<any>) {
    if (items?.length > 0) {
      for (let item of items) {
        if (['gem_legacy'].includes(item.id)) {
          await this._getTokenServizio(item);
        }
        if (item.figli?.length > 0)
          await this.checkForLegacyModules(item.figli);
      }
    }
    return true;
  }
  private async _getTokenServizio(menuItem: any) {
    return new Promise(async (resolve) => {
      let tokenServizio: any = await this._getTokenServizioAwait(menuItem.id);
      if (tokenServizio?.payload?.token) {
        //call login with credentials, automatically save sessionId in cookies
        let sessionId = await this._getLegacySessionId(menuItem, tokenServizio.payload.token);
        if (sessionId != null && sessionId != undefined && sessionId != '') {
          let menu: LegacyMenuItem = await this._getLegacyMenuItems(menuItem);
          if (menu?.Items) {
            this._getLegacyMenuFormatted(menu.Items, menuItem.url, menuItem.id);
            menuItem.figli = menu.Items;
          }
        }
      }
      resolve(true);
    })
  }
  //get token servizio api request
  private _getTokenServizioAwait(moduloId) {
    return new Promise((resolve) => {
      let payload = {
        "idModulo": moduloId
      }
      this.http.post(`${this._httpUtilitySrv.links.server}getTokenServizio`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        resolve(null);
      })
    });
  }
  private async _getLegacySessionId(legacyItem: any, token: string) {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append('token', token);
      this.http.post(this.decodeUrl(legacyItem.url) + (endpoint[legacyItem.id] ? endpoint[legacyItem.id].login : ''), formData, { withCredentials: true, responseType: 'text' })
        .subscribe((res: any) => {
          resolve(res);
        }, (err) => {
          resolve(null);
        })
    });
  }
  private async _getLegacyMenuItems(legacyItem: any): Promise<LegacyMenuItem> {
    return new Promise((resolve) => {
      this.http.get(this.decodeUrl(legacyItem.url) + (endpoint[legacyItem.id] ? endpoint[legacyItem.id].menu : ''), { withCredentials: true })
        .subscribe((res: any) => {
          resolve(res as LegacyMenuItem);
        }, (err) => {
          resolve(null);
        })
    });
  }
  private _getLegacyMenuFormatted(legacyMenu: Array<LegacyMenuItem>, baseurl: string, parentId: string) {
    if (legacyMenu?.length) {
      for (let legacyItem of legacyMenu) {
        legacyItem.url = legacyItem.NavigateUrl ? ("/legacy?legacyUrl=" + baseurl + JSON.parse(JSON.stringify(legacyItem.NavigateUrl))) : null;
        legacyItem.NavigateUrl = legacyItem.NavigateUrl ? baseurl + JSON.parse(JSON.stringify(legacyItem.NavigateUrl)) : null;
        legacyItem.id = parentId + '.' + legacyItem.Text;
        legacyItem.nome = legacyItem.Text;
        legacyItem.idtipoModulo = legacyItem.NavigateUrl ? 2 : 1;
        legacyItem.figli = legacyItem?.Items?.length ? JSON.parse(JSON.stringify(legacyItem.Items)) : null;
        legacyItem.tipoRoot = "MAIN";
        legacyItem.legacyFrontend = parentId.indexOf('.') != -1 ? parentId : parentId.substring(0, parentId.indexOf('.'));
        if (legacyItem.figli?.length)
          this._getLegacyMenuFormatted(legacyItem.figli, baseurl, legacyItem.id);
      }
    }
    return true;
  }
  public decodeUrl(url: string): string {
    try {
      return decodeURIComponent(url);
    } catch (e) {
      return null;
    }
  }
  private _getLegacyMenuItemByUrl(url: string, menuItems: Array<any>): LegacyMenuItem {
    if (menuItems?.length > 0) {
      menuItems.forEach((menuItem) => {
        if (menuItem.legacyFrontend && menuItem.NavigateUrl == url) {
          return menuItem;
        }
        if (menuItem.figli?.length > 0) {
          this._getLegacyMenuItemByUrl(url, menuItem.figli);
        }
      })
    }
    return null;
  }

  //silentLoginLegacy
  public silentLoginLegacy(legacyMenuItem: LegacyMenuItem) {
    return new Promise(async (resolve) => {
      let tokenServizio: any = await this._getTokenServizioAwait(legacyMenuItem.id);
      if (tokenServizio?.payload?.token) {
        //call login with credentials, automatically save sessionId in cookies
        await this._getLegacySessionId(legacyMenuItem, tokenServizio.payload.token);
        resolve(true);
      }
      resolve(true);
    })
  }
  //getCurrentLegacyFrontend
  public getCurrentLegacyFrontend(): LegacyMenuItem {
    return this._currentLegacyMenuItem;
  }
}
export const endpoint = {
  gem_legacy: { login: "/login.aspx?nascondiMenu=true", menu: "/IFrameHandler.ashx" }
}
export interface LegacyMenuItem {
  IconName: string,
  NavigateUrl: string,
  Target: string,
  Text: string,
  ToolTip: string,
  Value: string,
  Items: Array<LegacyMenuItem>
  url: string,
  id: string,
  idtipoModulo: number,
  nome: string,
  figli: Array<LegacyMenuItem>,
  tipoRoot: string,
  legacyFrontend: string,
  params: any,
}