<div class="chat-bot" [style.bottom]="chatBot.bottom + 'px'" [style.right]="chatBot.right + 'px'"
  [hidden]="!chatBot.show">
  <div class="chat-bot-inner">
    <div class="chat-bot-title">
      <div class="cbt-data">
        <span>{{'labels.chatbot' | translate}}</span>
        <div *ngIf="chatBot.currentOption">{{chatBot.currentOption.nome}}</div>
        <span *ngIf="chatBot.fine">{{('labels.verbal'|translate)+' ' + chatBot.fine.text}}</span>
      </div>
      <div class="title-buttons">
        <ion-icon name="browsers-outline" (click)="openTablesPrompt()" [hidden]="!chatBot.datasetJson"
          title="{{'buttons.extractExcel' | translate }}"></ion-icon>
        <ion-icon name="refresh" (click)="refreshChatBot()"
          [hidden]="!chatBot.currentOption && chatBot.chat.length == 0"></ion-icon>
        <ion-icon name="remove" (click)="chatBot.show = false"></ion-icon>
      </div>
    </div>
    <div class="chat-bot-start" [hidden]="chatBot.chat.length > 0 || chatBot.currentOption">
      <div class="cbs-message">{{'labels.chatBotStart'| translate}}</div>
      <ion-row>
        <!-- customer -->
        <ion-col size-md="12" size-sm="12" size-xs="12">
          <ion-item class="input-field" lines="none">
            <ion-label position="stacked">
              {{'labels.customer' | translate}}</ion-label>
            <ionic-selectable [(ngModel)]="chatBot.customer" itemValueField="value" [items]="null" itemTextField="text"
              [canSearch]="true" [isMultiple]="false" closeButtonText="{{'buttons.close' | translate}}"
              placeholder="{{'labels.select' | translate}}"
              searchPlaceholder="{{'labels.search' | translate}} ({{'labels.atLeast3Characters' | translate}})"
              closeButtonSlot="end"
              (onSearch)="getListByEndpoint($event, 'https://test.safogroup.eu/SafoMs-ApiGateway/MulteServizio/Clienti/GetClienteByDenominazione?maxResult=50', 3)"
              [searchDebounce]="500" [shouldFocusSearchbar]="true" [shouldFocusSearchbar]="true" id="selectableCliente"
              (ngModelChange)="chatBot.fine = null" #selectableCliente>
            </ionic-selectable>
          </ion-item>
        </ion-col>
        <!-- fine -->
        <ion-col size-md="12" size-sm="12" size-xs="12">
          <ion-item class="input-field" lines="none" [class.selectable-disabled]="!chatBot.customer">
            <ion-label position="stacked">
              {{'labels.fine' | translate}}</ion-label>
            <ionic-selectable [(ngModel)]="chatBot.fine" itemValueField="value" [items]="null" itemTextField="text"
              [canSearch]="true" [isMultiple]="false" closeButtonText="{{'buttons.close' | translate}}"
              placeholder="{{'labels.select' | translate}}"
              searchPlaceholder="{{'labels.search' | translate}} ({{'labels.atLeast3Characters' | translate}})"
              closeButtonSlot="end"
              (onSearch)="getListByEndpoint($event, 'https://test.safogroup.eu/SafoMs-ApiGateway/MulteServizioProd/Multe/GetMultaByNumeroVerbale?idCliente=#customerId#&datiCompleti=true&maxResult=50', 3)"
              [searchDebounce]="500" [shouldFocusSearchbar]="true" [shouldFocusSearchbar]="true" id="selectableCliente"
              #selectableCliente>
            </ionic-selectable>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>
    <div class="chat-bot-chat" [hidden]="chatBot.chat.length == 0 && !chatBot.currentOption">
      <div class="chat-bot-message" *ngFor="let message of chatBot.chat;let i = index;"
        [ngClass]="{'message-left':['ai', 'error'].includes(message.type),'message-right':message.type == 'human'}"
        [class.message-error]="message.type == 'error'" id="message-{{i}}">
        <div class="chat-bot-message-inner">
          <div class="cbmi-text">{{message.testo}}</div>
          <div class="cbmi-options" *ngIf="message.opzioni">
            <div class="cmbio-option" *ngFor="let option of message.opzioni" title="{{option.descrizione}}"
              (click)="handleOption(option)">
              {{option.nome}}
            </div>
          </div>
          <div class="cmbi-buttons">
            <ion-icon name="browsers-outline" (click)="openTablesPrompt(message.datasetJson)"
              [hidden]="!message.datasetJson" title="{{'buttons.extractExcel' | translate }}"></ion-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-bot-input">
      <input [disabled]="chatBot.isLoading" placeholder="{{'labels.ask' | translate}}" [(ngModel)]="text"
        [hidden]="!chatBot.currentOption" (keyup)="searchKeyUp($event)">
      <ion-button size="small" [hidden]="!chatBot.currentOption" (click)="ask()"
        [disabled]="!text || text == '' || chatBot.isLoading"><ion-spinner  [hidden]="!chatBot.isLoading"
          name="lines-small"></ion-spinner> <ion-icon name="send" [hidden]="chatBot.isLoading"></ion-icon></ion-button>
      <span [hidden]="chatBot.currentOption"></span>
      <ion-button expand="block" [hidden]="chatBot.chat.length > 0 || chatBot.currentOption"
        [disabled]="!chatBot.customer || !chatBot.fine || chatBot.isLoading" (click)="ask()"><ion-spinner
          *ngIf="chatBot.isLoading" name="lines-small"></ion-spinner><ion-label>{{'labels.start'|
          translate}}</ion-label></ion-button>
    </div>
  </div>
</div>
<div class="chat-bot-button" (click)="chatBot.show = !chatBot.show" [hidden]="chatBot.show"
  [class.chat-bot-button-pinned]="chatBot.isButtonPinned" (mouseover)="chatBot.isButtonPinned = false">
  <ion-icon name="chatbox-ellipses-outline"></ion-icon>
</div>